<template>
    <div v-cloak @drop.prevent="addDropFile" @dragover.prevent="toggleDropClasses">
        <v-file-input
            :key="key"
            v-model="localFile"
            :value="file"
            :label="label"
            :truncate-length="truncateLength"
            small-chips
            solo
            dense
            hide-details
            :multiple="multiple"
            density=""
        />
        <transition name="fade">
            <div v-if="showError">
                <p v-for="error in errors" :key="error" class="text-xs px-3 mt-1 text-red/50">{{ error }}</p>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        file: { type: Object, required: false, default: null },
        errors: { type: Array, required: false, default: null },
        label: { type: String, required: false, default: 'Click to upload files' },
        truncateLength: { type: Number, required: false, default: 100 },
        dialog: { type: Boolean, required: false, default: false },
        multiple: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            key: 0,
            localFile: null,
            showError: false,
        };
    },
    watch: {
        localFile(e) {
            this.$emit('input', e);
        },
        errors(e) {
            if (e) {
                this.showError = true;
            }
        },
        dialog(e) {
            if (e === false) {
                setTimeout(() => {
                    this.localFile = null;
                }, 500);
            }
        },
    },
    methods: {
        addDropFile(e) {
            this.localFile = e.dataTransfer.files[0];
            this.key++;
        },
        toggleDropClasses() {},
    },
};
</script>

<style>
#newSubmitAssetFileInput .v-input__slot {
    height: 4rem !important;
}

#newSubmitAssetFileInput .v-input__prepend-outer {
    margin-top: auto !important;
    margin-bottom: auto !important;
}
</style>
