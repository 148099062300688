<template>
    <v-dialog
        v-model="open"
        width="420"
        persistent
    >
        <div class="dialog-body">
            <modal-header
                :text="`Confirm status change to ${statusLabel}`"
                @closeModal="closeModal"
            />
            <h3>Add comment for the changes</h3>
            <div>
                <textarea
                    v-model="comment"
                    class="w-full h-32"
                    style="border: 1px solid gainsboro;"
                />
            </div>
            <div class="mt-4">
                <v-autocomplete
                    v-model="usersToNotify"
                    :items="sortedUsers"
                    :item-text="(item) => item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                    :menu-props="{ closeOnContentClick: true }"
                    class="user-badges"
                    label="Notify members"
                    multiple
                    solo
                    dense
                    chips
                    return-object
                    deletable-chips
                >
                    <template #selection="data">
                        <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="usersToNotify = usersToNotify.filter((user) => user.id !== data.item.id)"
                        >
                            <user-badge :user="data.item" is-small full-name />
                        </v-chip>
                    </template>
                    <template #item="{ item }">
                        <div :key="Math.random()" class="w-full">
                            <user-badge :user="item" full-name />
                        </div>
                    </template>
                </v-autocomplete>
            </div>
            <div v-if="status === 3" class="mt-4">
                <h3>Add Timesheet hours</h3>
                <v-text-field
                    v-model="hours"
                    placeholder="Add hours spent on task"
                    class="mt-2"
                    hide-details
                    solo
                    dense
                    @keyup="handleHours"
                />
            </div>

            <footer class="dialog-footer">
                <div class="col-span-3" />
                <div class="col-span-2">
                    <v-btn
                        :disabled="status === 3 && (isNaN(parseFloat(hours)) || hours < 0)"
                        color="primary"
                        block
                        @click="onSave"
                    >
                        Save
                    </v-btn>
                </div>
            </footer>

        </div>
    </v-dialog>
</template>

<script>
// load user last 5 submit and generate checkbox
import { mapGetters } from 'vuex';

export default {
    props: {
        open: { type: Boolean, required: true },
        task: { type: Number, required: false, default: 0 },
        status: { type: Number, required: false, default: 0 },
        taskObject: { type: Object, required: false, default: null },
    },
    data() {
        return {
            submits: [],
            comment: '',
            sortedUsers: [],
            usersToNotify: [],
            hours: '',
        };
    },
    computed: {
        ...mapGetters({
            users: 'config/getUsers',
        }),
        statusLabel() {
            if (this.$store?.state?.config?.config?.dispo?.task_statuses?.find)
                return this.$store.state.config.config.dispo.task_statuses.find(x => x.id === this.status)?.label;
            return '';
        },
    },
    watch: {
        open(e) {
            if (e === true) {
                this.resetData();
            }
        },
    },
    beforeMount() {
        this.sortedUsers = this.users;
    },
    mounted() {
       this.comment = '';
    },
    updated() {
        if (!this.open) {
            this.comment = '';
        }
    },
    methods: {
        onSave() {
            const postData = new FormData();
            this.usersToNotify.forEach((user) => {
                postData.append('usersToNotify[]', user.id);
            });
            postData.append('comment', this.comment);
            if (this.status === 3) {
                postData.append('timeSheetHours', this.hours);
            }

            this.$axios
                .post(`${process.env.prodAPI}/taskcomment/${this.task}`, postData)
                .catch((e) => {
                    for (const error of e.response.data.errors) {
                        this.$notify({
                            title: e.response.data.message,
                            text: `${error.key}: ${error.message}`,
                            type: 'error'
                        });
                    }
                });
            let returnData = this.taskObject;
            if (returnData === null) {
                returnData = this.task;
            }
            this.$emit('confirmed', returnData, this.status);
        },
        closeModal() {
            this.resetData();
            this.$emit('canceled');
        },
        handleHours() {
            this.hours = this.hours.replace(/[^\d,.-]+/g, '').replace(',', '.');
            if (!isNaN(parseFloat(this.hours))) {
                if (this.hours < 0) {
                    this.hours = String(Math.max(this.hours, 0));
                }
            }
        },
        resetData() {
            this.usersToNotify = [];
            this.comment = '';
            this.hours = '';
        },
    }
}
</script>