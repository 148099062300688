<template>
    <div class="main login-page relative min-w-screen min-h-screen" data-app>
        <div class="login-image absolute top-0 left-0 w-full h-full bg-image z-10">
            <img :src="require(`/assets/img/contents/${selectedBackground.src || ''}`)" alt="" />
        </div>

        <div v-if="serverStatuses" class="absolute bottom-12 right-24 z-10 text-white">
            <span class="status-badge">
                AD
                <span class="indicator" :class="{ active: serverStatuses.activeDirectory }"></span>
            </span>

            <span class="status-badge">
                Afanasy
                <span class="indicator" :class="{ active: serverStatuses.afanasy }"></span>
            </span>

            <span class="status-badge">
                WSS
                <span class="indicator" :class="{ active: serverStatuses.websocket }"></span>
            </span>
        </div>
        <div class="login-container relative h-screen w-screen z-20 flex justify-center items-center">
            <div class="login-top absolute top-0 left-0 w-full pt-9 px-8 flex justify-between items-center">
                <div>
                    <nav class="logo flex items-center">
                        <nuxt-link to="/" class="text-white text-2xl flex items-center">
                            <img :src="logoSource" class="h-8 mr-3 fill-current" alt="" />
                        </nuxt-link>
                    </nav>
                </div>
                <div>
                    <div class="weather text-white text-2xl">
                        <div class="flex h-10 justify-end items-center">
                            <div class="w-10 h-10 inline-flex items-center justify-center text-3xl mr-2">
                                <img :src="weather.icon" alt="" />
                            </div>
                            <p class="text-2xl leading-6">{{ weather.temp }}°C</p>
                        </div>
                        <div class="text-right">
                            <p class="text-2xl capitalize">{{ weather.location }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="login-bottom absolute bottom-0 left-0 w-full z-20 pb-10 px-8 flex justify-between items-center">
                <div class="w-6/12">
                    <div class="text-white font-gotham-500 inline-flex items-center">
                        <i class="lab la-imdb text-4xl" />
                        <span class="ml-4">{{ selectedBackground.name }} </span>
                        <span class="inline-block ml-1 font-gotham-400">({{ selectedBackground.year }})</span>
                    </div>
                </div>
                <div class="w-6/12 text-white text-right">
                    <p>{{ currentTime }}</p>
                </div>
            </div>
            <div class="flex w-full items-center justify-center">
                <div class="hidden lg:block w-0 lg:w-1/3 px-16">
                    <p class="text-3xl text-white">
                        "{{ selectedBackground.quote }}"<br />
                        <strong class="font-gotham-700 text-2xl">{{ selectedBackground.author }}</strong>
                    </p>
                </div>
                <div class="w-1/3 min-w-100">
                    <Nuxt />
                </div>
                <div class="w-0 lg:w-1/3"></div>
            </div>
        </div>

        <notifications-wrapper />
    </div>
</template>

<script>
import NotificationsWrapper from '~/components/UI/Notifications/NotificationsWrapper.vue';

export default {
    name: 'GuestLayout',
    components: {
        NotificationsWrapper,
    },
    data() {
        return {
            weather: {
                location: 'Budapest',
                temp: '',
                icon: null,
            },
            sunset: null,
            serverStatuses: null,
            sunrise: null,
            backgrounds: [
                {
                    name: 'Star Wars',
                    src: 'bg-starwars.png',
                    quote: 'Raaarwh',
                    year: 1977,
                    author: 'Chewbacca',
                },
                {
                    name: 'Spider-Man: Homecoming',
                    src: 'bg-spiderman.jpg',
                    quote: 'With great power comes great responsibility',
                    year: 2017,
                    author: 'Spider-Man',
                },
                {
                    name: 'Pulp Fiction',
                    src: 'bg-pulp.jpg',
                    quote: 'English, motherfucker, do you speak it?',
                    year: 1994,
                    author: 'Jules Winnfield',
                },
                {
                    name: 'Lord of the Rings',
                    src: 'bg-lotr.jpg',
                    quote: 'All we have to decide is what to do with the time that is given us.',
                    year: 2001,
                    author: 'Gandalf',
                },
                {
                    name: 'Aliens',
                    src: 'Alien_2.jpg',
                    quote: 'Get away from her, you bitch',
                    year: 1986,
                    author: 'Ellen Ripley',
                },
                {
                    name: 'Apocalypse now',
                    src: 'Apocalypse_now_2.jpg',
                    quote: 'I love the smell of napalm in the morning ',
                    year: 1979,
                    author: 'Lt. Col. Bill Kilgore',
                },
                {
                    name: 'Apollo 13',
                    src: 'Apollo_13_2.jpg',
                    quote: 'Houston, we have a problem',
                    year: 1995,
                    author: 'Jim Lovell',
                },{
                    name: 'Die Hard',
                    src: 'diehard_2.jpg',
                    quote: 'Yippee-ki-yay, motherfucker',
                    year: 1988,
                    author: 'John McClane',
                },{
                    name: 'Fear and Loathing in Las Vegas',
                    src: 'fearand_2.jpg',
                    quote: 'We were somewhere around Barstow on the edge of the desert when the drugs began to take hold',
                    year: 1998,
                    author: 'Raul Duke',
                },{
                    name: 'Forrest Gump',
                    src: 'forrest_gump_2.jpg',
                    quote: 'My mama always said, life was like a box of chocolates, you never know what you gonna get',
                    year: 1994,
                    author: 'Forrest Gump',
                },{
                    name: 'Godfather',
                    src: 'godfather_2.jpg',
                    quote: 'Some day, and that day may never come, I will call upon you to do a service for me.',
                    year: 1972,
                    author: 'Vito Corleone',
                },{
                    name: 'Inglourious Basterds',
                    src: 'inglorious_2.jpg',
                    quote: 'Gorlami!',
                    year: 2009,
                    author: 'Aldo Raine ',
                },{
                    name: 'Jaws',
                    src: 'Jaws_2.jpg',
                    quote: 'You\'re gonna need a bigger boat',
                    year: 1975,
                    author: 'Chief Brody',
                },{
                    name: 'Lion King',
                    src: 'LionKing_2.jpeg',
                    quote: 'Hakuna Matata',
                    year: 1994,
                    author: 'Timon and Pumbaa',
                },{
                    name: 'Rick and Morty',
                    src: 'rickandmorty_2.jpg',
                    quote: 'wubba lubba dub dub',
                    year: 2013,
                    author: 'Rick Sanchez',
                },{
                    name: 'Sixth Sense',
                    src: 'Sixth_2.jpg',
                    quote: 'I see dead people.',
                    year: 1999,
                    author: 'Cole Sear',
                },{
                    name: 'Le haine',
                    src: 'lehaine.jpeg',
                    quote: 'How you fall doesn\'t matter. It\'s how you land!',
                    year: 1995,
                    author: 'Hubert Koundé',
                },{
                    name: 'The Sopranos',
                    src: 'sopranos.webp',
                    quote: 'Those who want respect, give respect.',
                    year: 1999,
                    author: 'Tony Soprano',
                },{
                    name: 'Snatch',
                    src: 'Snatch_2.jpg',
                    quote: 'Yes, London, you know, fish, chips, cup o tea, bad food, worse weather, Mary fucking Poppins, London.',
                    year: 2000,
                    author: 'Charlie/ Cousin Avi',
                },{
                    name: 'Taxi Driver ',
                    src: 'taxidrive_2.jpg',
                    quote: 'You talkin\' to me? ',
                    year: 1976,
                    author: 'Travis Bickle',
                },{
                    name: 'Tropic Thunder',
                    src: 'Tropic_thunder_2.jpg',
                    quote: 'Everybody knows you never go full retard',
                    year: 2008,
                    author: 'Kirk Lazarus',
                },{
                    name: 'Tropic Thunder',
                    src: 'Tropic_thunder_3.jpg',
                    quote: 'Okay. Flaming Dragon. Fuckface. First, take a big step back and literally fuck your own face!',
                    year: 2008,
                    author: 'Les Grossman',
                }
            ],
            selectedBackground: null,
        };
    },
    fetch() {
        this.$axios
            .$get(
                `//api.weatherapi.com/v1/current.json?key=${this.$config.weatherApiKey}&q=${this.weather.location}&aqi=no`
            )
            .then((res) => {
                this.weather.temp = res.current.temp_c;
                this.weather.icon = res.current.condition.icon;
            })
            .catch((e) => {
                const error = new Error(e.response.data.message);
                error.statusCode = e.response.status;
                throw error;
            });

        this.$axios.$get(process.env.prodAPI + '/config/server').then((res) => {
            this.serverStatuses = res.result.statuses;
        });
    },
    computed: {
        currentTime() {
            return this.$moment().format('H:mm');
        },
        logoSource() {
            return `${process.env.backendURL}/assets/system/logo.svg`;
        },
    },
    beforeMount() {
        const random = Math.floor(Math.random() * this.backgrounds.length);
        this.selectedBackground = this.backgrounds[random];

        const T_1000 = this.$moment(new Date()).startOf('day').add(10, 'hours');
        const T_1800 = this.$moment(new Date()).startOf('day').add(18, 'hours');
        if (this.$moment(new Date()).isBefore(T_1000)) {
            this.$store.commit('setDayTime', 'morning');
        }
        if (this.$moment(new Date()).isAfter(T_1000) && this.$moment(new Date()).isBefore(T_1800)) {
            this.$store.commit('setDayTime', 'day');
        } else {
            this.$store.commit('setDayTime', 'evening');
        }
    },
};
</script>

<style lang="postcss" scoped>
.login-image:after {
    content: '';
    @apply absolute top-0 left-0 w-full h-full bg-black/70;
}
.w-26p {
    width: 26%;
}
.w-37p {
    width: 37%;
}
.logo,
.logo path,
.logo g {
    color: #fff;
}

.status-badge {
    @apply bg-dark-800 rounded py-2 px-3 text-xxs mx-2 inline-flex items-center;
    .indicator {
        @apply w-1.5 h-1.5 rounded-full ml-3;
        &.active {
            @apply bg-success;
        }
        &:not(.active) {
            @apply bg-red-500;
        }
    }
}
</style>
