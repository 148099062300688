<template>
    <div class="ml-2 relative z-10">
        <v-menu
            ref="headerUserActionsMenu"
            offset-y
            open-on-hover
            open-on-focus
            close-on-content-click
            close-delay="200"
            persistent
        >
            <template #activator="{ on }">
                <v-btn icon v-on="on" @click="() => { justOpened = true }">
                    <img :src="getAvatarImage(me.avatar)" alt="" class="w-7 h-7 object-cover rounded-full" />
                </v-btn>
            </template>
            <div
                v-click-outside="() => { if (!justOpened) $refs.headerUserActionsMenu._data.isActive = false; else justOpened = false; }"
                class="v-menu-body"
            >
                <v-btn
                    v-if="$checkPermission(['dispo_entry.read_list'])"
                    medium
                    block
                    color="tertiary"
                    to="/me?tab=dispo"
                >
                    My Dispo
                </v-btn>
                <v-btn
                    v-if="$checkPermission('project.read_list')"
                    medium
                    block
                    color="tertiary"
                    to="/me?tab=my_projects"
                >
                    My Projects
                </v-btn>
                <v-btn v-if="$checkPermission('task.read_list')" medium block color="tertiary" to="/me?tab=my_tasks">
                    My Tasks
                </v-btn>
                <v-btn medium block color="tertiary" to="/downloads">
                    Downloads
                </v-btn>
                <v-btn
                    v-if="$checkPermission(['timesheet_entry.read_list', 'task.read_list'])"
                    medium
                    block
                    color="tertiary"
                    to="/me?tab=timesheet"
                >
                    Timesheet
                </v-btn>
                <v-btn medium block color="tertiary" to="/me?tab=settings">Settings</v-btn>
                <v-btn medium block color="tertiary" to="/me?tab=shortcuts">Shortcuts</v-btn>
                <v-btn medium block color="tertiary" href="https://mail.gyar.hu/" target="_blank">Webmail</v-btn>
                <v-btn medium block color="tertiary" href="http://afanasy.gyar.com:51322/" target="_blank">Afanasy</v-btn>
                <v-btn medium block color="tertiary" @click="feedbackModalOpen = true">Report Bug</v-btn>
                <v-btn medium block color="tertiary" @click="logout">Log out</v-btn>
            </div>
        </v-menu>

        <span v-hotkey="{ 'alt+b': () => {feedbackModalOpen = true} }" class="hidden" />
        <feedback-modal
            :show-modal="feedbackModalOpen"
            @updateModal="(val) => {feedbackModalOpen = val}"
            @saveModal="() => {feedbackModalOpen = false}"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FeedbackModal from '@/components/Feedbacks/FeedbackModal.vue';
export default {
    components: { FeedbackModal },
    data() {
        return {
            feedbackModalOpen: false,
            justOpened: false,
        }
    },
    computed: {
        ...mapGetters({
            me: 'users/me/getMe',
        }),
    },
    methods: {
        logout() {
            this.$store.dispatch('auth/logout');
            setTimeout(() => {
                this.$store.dispatch('users/me/reset');
            }, 1000);
        },
    },
};
</script>

<style lang="postcss" scoped>
.user_actions {
    &-enter,
    &-leave-to {
        @apply opacity-0;
        @apply transform -translate-y-3;
    }

    &-enter-active,
    &-leave-active {
        @apply transition-all;
    }

    &-enter-to,
    &-leave {
        @apply opacity-100;
        @apply transform translate-y-0;
    }
}
.main .v-btn.theme--light.v-btn--active:hover::before,
.main .v-btn.theme--light.v-btn--active::before {
    @apply opacity-0;
}
</style>
